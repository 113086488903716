
import Base from '../components/Base/Base';
import CFPHero from '../components/CallForPaper/CFPHero';


const CallForPapers = () => {
    return(
        <Base>
        <CFPHero/>
        </Base>
    )
}

export default CallForPapers;