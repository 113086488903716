
import Base from '../components/Base/Base';
import AboutHero from '../components/About/AboutHero';


const About = () => {
    return(
        <Base>
        
        <AboutHero />
        </Base>
    )
}

export default About;