
import Base from '../components/Base/Base';
import SubHero from '../components/Submission/SubHero';


const Submission = () => {
    return(
        <Base>
        <SubHero/>
        </Base>
    )
}

export default Submission;