import "./FPSub.css";
import Fade from "react-reveal/Fade";


const FPSub = () => {
    return(
        <div >

        {/* ----------------------------- */}
          <header className="hero">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center hero-content">
              <h1>FINAL PAPER SUBMISSION</h1>
            </div>
          </div>
        </div>
      </header>
      

     {/* ----------------------------- */}

     <Fade bottom>
        <div className="container  ">
        <div className="row">
          <div className="col-12 FPS ">
            <h2>Final paper Submission</h2>
            <p>
            When the review process has been completed, authors receive a notification of either acceptance or rejection of the submission. If the paper has been accepted, the authors can prepare a full paper that incorporates the reviewers’ suggestions. The format for the full papers is identical to the format for the initial paper except for the number of pages: the full paper has a required minimum length of five (5) pages and a maximum of six (6) pages.
            </p>
          </div>
        </div>
      </div>
      </Fade>

           {/* ----------------------------- */}

           <Fade bottom>
           <div className="container  FPS">
        <div className="row">
          <div className="col-12 ">
            <h2>IMPORTANT:</h2>
            <p>
            REMOVE THE FOOTER IEEE FROM THE TEMPLATE (XXX-X-XXXX-XXXX-X/XX/$XX.00 ©20XX).
            </p>
          </div>
        </div>
      </div>
      </Fade>

        {/* ----------------------------- */}


        <Fade bottom>
        <div className="container">
        <div className="row">
          <div className="col-12 FPS ">
            <h2>Generate an IEEE Xplore Compatible Manuscript:</h2>
            <p>
            Since the Conference Proceedings are going to be published in the IEEE Xplore Digital Library, the file containing the final version of the paper must be converted to PDF format using the IEEE PDF eXpress web tool, or if the PDF file has been generated by another software, it must be checked by the IEEE PDF eXpress web tool.
            </p>
          </div>
        </div>
      </div>
      </Fade>

        {/* ----------------------------- */}

        <Fade bottom>
        <div className="container">
        <div className="row">
          <div className="col-12 FPS">
            <p>
            To generate the IEEE Xplore compatible PDF file of your paper, follow these steps: 
            </p>
            <ol>
                <li>Create your IEEE PDF eXpress account: https://ieee-pdf-express.org/account/login</li>
                <li>Conference ID: </li>
                <li>The first time you access the system, please follow the link to new user. Please note that in order to access the service, you need to allow the use of cookies from the PDF eXpress web site. Once you have registered as a new user:</li>
            </ol>
            <ol typeof="a">
            <li>Upload the source file (containing your paper) for conversion.</li>
            <li>Receive by e-mail the IEEE Xplore-compatible PDF of your paper.</li>
            </ol>
          </div>
        </div>
      </div>
      </Fade>

         {/* ----------------------------- */}

         <Fade bottom>
         <div className="container">
        <div className="row">
          <div className="col-12 FPS">
            <h2>Copyright Form and Paper Upload:</h2>

            <p>
          To upload your IEEEXplore-compatible PDF final paper to CMT Login:
            </p>
            <ol>
            <li>Log in at CMT</li>
            <li>Select the “My Papers” tab</li>
            </ol>
            <p>
            Select the paper title you wish to upload the final paper for to be directed to the paper record.
            </p>
          </div>
        </div>
      </div>
      </Fade>

          {/* ----------------------------- */}

          <Fade bottom>
          <div className="container">
        <div className="row">
          <div className="col-12 FPS">
            <h3>COMPLETE THE ELECTRONIC COPYRIGHT FORM VIA EDAS</h3>
            <p>
            IEEE is enforcing strict copyright rules. For your convenience, the copyright form is made available electronically through CMT. To access the copyright form, select the icon in the copyright column within your paper record.
            </p>
          </div>
        </div>
      </div>
      </Fade>

       {/* ----------------------------- */}

       <Fade bottom>
       <div className="container">
        <div className="row">
          <div className="col-12 FPS">
            <h3>UPLOAD YOUR FINAL PAPER</h3>
            <p>
            Upload your paper directly to “Camera Ready Submission” by selecting the upload icon.
            </p>
          </div>
        </div>
      </div>
      </Fade>

      </div>
    )
}

export default FPSub;