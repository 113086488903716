
import Base from '../components/Base/Base';


const Speakers = () => {
    return(
        <Base>
        <h1>Speakers</h1>
        </Base>
    )
}

export default Speakers;