
import Base from '../components/Base/Base';
import Reg from '../components/Registration/Reg';


const Registration = () => {
    return(
        <Base>
        <Reg/>
        </Base>
    )
}

export default Registration;