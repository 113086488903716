
import Base from '../components/Base/Base';
import FPSub from '../components/FPSub/FPSub';


const FinalPaperSubmission = () => {
    return(
        <Base>
        <FPSub/>
        </Base>
    )
}

export default FinalPaperSubmission;