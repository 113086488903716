import "./CFPHero.css";
import Fade from "react-reveal/Fade";

const CFPHero = () => {
  return (
    <>
      <header className="hero">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center hero-content">
              <h1>Call For Paper</h1>
            </div>
          </div>
        </div>
      </header>

      {/* ------------------------------ */}

      <Fade bottom>
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <h2>Important Dates:</h2>
              <ul>
                <li>Paper Submission starts on 01-June-2023</li>
                <li>Last Deadline for Early Bird Registration: 30-June-2023</li>
                <li>Last Deadline for Submission of papers: 20-October-2023</li>
                <li>Notification of Acceptance: 30-October-2023</li>
                <li>
                  Submission of camera-ready paper and Registration: 01-Nov-2023
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Fade>

      {/* ------------------------------ */}

      <Fade bottom>
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <h1>Call for Papers:</h1>
              <p>
                The Forth International Conference on Smart Technologies in
                Computing, Electrical and Electronics (ICSTCEE 2023) will be
                held at REVA University, Bengaluru, India on 8 & 9 December
                2023. REVA University hosts this ICSTCEE conference as a part of
                REVA Research Conclave (RRC) 2023. RRC is a great platform for
                encouraging the new generation towards research, innovation and
                entrepreneurship by listing to outstanding speakers from
                Academia, Entrepreneurs and Achievers from Industry. It also
                provides a great platform for budding researchers to get
                feedback about their quality of research from eminent
                researchers across the globe. The ICSTCEE conference presents an
                open forum for researchers, specialists and designers to
                exchange the most recent developments and research headways in
                the areas of cutting-edge computing and hardware technologies,
                Smart cities, Smart manufacturing, Smart vehicles, and mobile
                applications. The technical program includes plenary sessions
                (invited keynote lectures), regular technical sessions, poster
                sessions, panel discussions and industry exhibition.ll for
              </p>
            </div>
          </div>
        </div>
      </Fade>

      {/* ------------------------------ */}

      <Fade bottom>
        <div className="container">
          <div className="row">
            <div>
              {/* <div className="col-12 manuscript ">
              <a href="https://cmt3.research.microsoft.com/User/Login?ReturnUrl=%2FICSTCEE2022">
                Upload You Manuscript
              </a>
            </div> */}
              <br></br>
              <p>
                Papers accepted and presented in the conference will be
                submitted for possible inclusion into the
                <b> IEEE Xplore® Digital Library.</b>
              </p>
            </div>
          </div>
        </div>
      </Fade>

      {/* ------------------------------ */}

      <Fade bottom>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="cal-info-head">
                {/* <h1>Call for paper</h1> */}
                <p className="justify">
                  We welcome submissions for ICSTCEE’s technical program in the
                  following areas and not limited to:
                </p>
              </div>
            </div>
          </div>
        </div>
      </Fade>

      {/* ------------------------------ */}

      <Fade bottom>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Computer Engineering</th>
                      <th>Electrical and Electronics</th>
                      <th>Electronics and Communication</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Database Management Systems</td>
                      <td>Avionics Home/Office Automation</td>
                      <td>Low Power VLSI Circuits & Mixed</td>
                    </tr>
                    <tr>
                      <td>Software Engineering</td>
                      <td>Vehicular Communication Techniques</td>
                      <td>Mode VLSI</td>
                    </tr>
                    <tr>
                      <td>Systems Software</td>
                      <td>Power quality and mitigation techniques</td>
                      <td>Optical and Photonic Networks</td>
                    </tr>
                    <tr>
                      <td>Data Communication and Networking</td>
                      <td> FACTS, HVDC, Custom Power devices</td>
                      <td>Antenna, Filters, Waveguides</td>
                    </tr>
                    <tr>
                      <td>Internet of Things</td>
                      <td> High Voltage Engineering</td>
                      <td> VLSI Verification and Testing</td>
                    </tr>
                    <tr>
                      <td>Management Information System</td>
                      <td>Electric Regulations</td>
                      <td>Robotics and automation</td>
                    </tr>
                    <tr>
                      <td>Artificial Intelligence</td>
                      <td>Advanced Engineering Materials</td>
                      <td>Next Generation Networks</td>
                    </tr>
                    <tr>
                      <td>Machine Learning & Deep Learning</td>
                      <td>Advanced Electrical drives</td>
                      <td>Smart Grid Domains</td>
                    </tr>
                    <tr>
                      <td>Information Processing</td>
                      <td>Green building Concepts</td>
                      <td>Spread Spectrum Communication</td>
                    </tr>
                    <tr>
                      <td>Cloud Computing</td>
                      <td> Induction heating System</td>
                    </tr>
                    <tr>
                      <td>Bio Inspired Computing</td>
                      <td>Microprocessor Based Technologies</td>
                    </tr>
                    <tr>
                      <td>Bioinformatics</td>
                      <td>Power Electronics/Power Systems</td>
                    </tr>
                    <tr>
                      <td>Computer Vision</td>
                    </tr>
                    <tr>
                      <td>Expert Systems</td>
                    </tr>
                    <tr>
                      <td>Image Processing</td>
                    </tr>
                    <tr>
                      <td>Information Retrieval and Fusion</td>
                    </tr>
                    <tr>
                      <td>Big Data Analytics</td>
                    </tr>
                    <tr>
                      <td>Soft Computing</td>
                    </tr>
                    <tr>
                      <td>Data Engineering</td>
                    </tr>
                    <tr>
                      <td>Data Mining</td>
                    </tr>
                    <tr>
                      <td>Data Warehousing</td>
                    </tr>
                    <tr>
                      <td>Compiler Design</td>
                    </tr>
                    <tr>
                      <td>Computer System and Modeling</td>
                    </tr>
                    <tr>
                      <td>Operating System</td>
                    </tr>
                    <tr>
                      <td>Parallel and Distributed Computing</td>
                    </tr>
                    <tr>
                      <td>Media Technologies</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Fade>

      {/* ------------------------------ */}

      <hr></hr>

      <Fade bottom>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Best Paper Award:</h1>
              <div>
                <ol>
                  <li>
                    ICSTCEE Best Paper Award (Academia): INR 1,000 +Certificate
                  </li>
                  <li>
                    ICSTCEE Best Paper Award (Industry): INR 1,000 +Certificate
                  </li>
                </ol>
              </div>
              <p>
                To identify the industry or the academia category, the
                affiliation of most authors will be considered. If there is tie,
                then it will be decided based on the place where the hardware
                was actually realized.
              </p>
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default CFPHero;
